import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../vuex/store";
import goTo from "vuetify/es5/services/goto";
// import manage_merchant from "../vuex/modules/Merchant/manage_merchant";

// ADMIN
const Settings = async () => await import("./../admin/Settings");
const Users = async () => await import("./../admin/Users");
const OurPartners = async () => await import("./../admin/OurPartner");
const SendMessage = async () => await import("./../admin/SendMessage");
const GalleryRetailer = async () => await import("./../admin/GalleryRetailer");
const PluginDownloader = async () =>
  await import("./../admin/PluginDownloader");
const Login = async () => await import("./../admin/auth/Login");
const PasswordEmail = async () => await import("../admin/auth/password/email");
const PasswordReset = async () => await import("../admin/auth/password/reset");
const App = async () => await import("./../admin/App.vue");
const Dashboard = async () => await import("./../admin/Dashboard");
const Analytics = async () => await import("./../admin/Analytics");
const HomePageSection = async () => await import("../admin/HomePageSection");
const ManageMerchant = async () => await import("../admin/Manage_Merchants");
const Announcements = async () => await import("../admin/Announcements");
const Walkthrough = async () => await import("../admin/Walkthrough");
const MerchantOffers = async () => await import("../admin/MerchantOffers");
const MerchantCoupons = async () => await import("../admin/MerchantCoupons");
const MerchantViews = async () => await import("../admin/MerchantViews");
const AdminPackages = async () => await import("../admin/Packages");
const ContactMessage = async () => await import("../admin/ContactMessage");
const RegisterLogs = async () => await import("../admin/RegisterLogs");
const ReminderLogs = async () => await import("../admin/ReminderLogs");

const LogApp = async () => await import("../admin/Logs/LogApp");
const LogInfo = async () => await import("../admin/Logs/LogInfo");
const LogSettings = async () => await import("../admin/Logs/LogSettings");
const DeleteUsersLogs = async () =>
  await import("../admin/Logs/DeleteUsersLogs");

const notificationLogs = async () => await import("../admin/Notification/NotificationLogs.vue");
const NotificationUtilities = async () => await import("../admin/Notification/NotificationUtilities.vue");
const UserFeedback = async () => await import("../admin/UsersFeedback.vue");

// Admin Mail
const MailUtilities = async () =>
  await import("../admin/mail/MailUtilities.vue");
const MailLogs = async () => await import("../admin/mail/MailLogs.vue");

// Admin Lookup
const LookupCategory = async () => await import("../admin/lookup/Category.vue");
const LookupCategoryQuestion = async () =>
  await import("../admin/lookup/CategoryQusetion.vue");
const LookupQuestionAndAnswer = async () =>
  await import("../admin/lookup/QuestionAndAnswer.vue");

const UserInformationDetail = async () =>
await import("../admin/UserInformatiionDetail.vue");

const LogViewer = async () => await import("./../admin/auth/LogViewer");

// MERCHANT
const LoginMerchant = async () => await import("../merchant/LoginMerchant");
const MerchantGalleryRetailer = async () => await import("../merchant/MerchantGalleryRetailer");

const AppMerchant = async () => await import("../merchant/App");
const DashboardMerchant = async () => await import("../merchant/Dashboard.vue");
const Packages = async () => await import("../merchant/Packages");
const MerchantChangePassword = async () =>
  await import("../merchant/ChangePassword");
const MerchantCreateToken = async () => await import("../merchant/CreateToken");
const Websites = async () => await import("../merchant/Websites");
const ResetPassword = async () =>
  await import("../merchant/mh_auth/ResetPassword");
// const TermsAndConditions = async () =>
//   await import("../merchant/TermsAndConditions");
const ShippingAddress = async () => await import("../merchant/ShippingAddress");
// const PrivacyPolicy = async () => await import("../merchant/PrivacyPolicy");

//  USER
const UserLogin = async () => await import("../user/LoginUser");
const UserDashboard = async () => await import("../user/Dashboard");
const AppUser = async () => await import("../user/App");
const UserChangePassword = async () => await import("../user/ChangePassword");
const userFriends = async () => await import("../admin/UserFriends");
const txnHistory = async () => await import("../user/txnHistory");

const MyProfile = async () => await import("../user/MyProfile");
const MobileVerified = async () => await import("../user/MobileVerified");
const UserAddress = async () => await import("../user/UserAddress");
const UsersMobileNumbers = async () =>
  await import("../user/UsersMobileNumbers");
const UpcomingBirthday = async () => await import("../user/UpcomingBirthday");
const ForgetPassword = async () =>
  await import("../user/userAuth/ResetPassword");

// FRONTEND
// const AppMain = async () => await import("../frontend/AppMain");
// const HomePage = async () => await import("./../v3_pages/user_v3/HomeV3");
// const Individual = async () => await import("../frontend/Individual");
// const CustomerAcq = async () => await import("../frontend/CustomerAcq");
// const RetailerService = async () => await import("../frontend/RetailerService");
// const FAQ = async () => await import("../frontend/Faq");
// const AboutUs = async () => await import("../frontend/AboutUs");
// const RetailerPartnets = async () =>
//   await import("../frontend/RetailerPartners");
// const ContactUs = async () => await import("../frontend/ContactUs");
const SessionClear = async () => await import("../frontend/SessionClear");
// const DownloadPlugin = async () => await import("../frontend/DownloadPlugin");
const PageNotFound = async () => await import("../frontend/PageNotFound.vue");
const Share = async () => await import("../frontend/Share");



const Onboarding = async () =>
  await import("../merchant/onboarding/Onboarding");

const WelcomeMerchant = async () =>
  await import("../merchant/onboarding/WelcomeMerchant");

const ChooseWebsite = async () =>
  await import("../merchant/onboarding/ChooseWebsite");

const InstallPlugin = async () =>
  await import("../merchant/onboarding/InstallPlugin");

const ConfigurePlugin = async () =>
  await import("../merchant/onboarding/ConfigurePlugin");

////////////
import MerchantSignIn from "../v3_pages/merchant/auth/MerchantSignIn.vue";
import MerchantEnterPassword from "../v3_pages/merchant/auth/MerchantEnterPassword.vue";
import MerchantResetPassword from "../v3_pages/merchant/auth/MerchantResetPassword.vue";
import MerchantRegister from "../v3_pages/merchant/auth/MerchantRegister.vue";
import MerchantOtp from "../v3_pages/merchant/auth/MerchantOtp.vue";
import MerchantRegisterYourDetail from "../v3_pages/merchant/auth/MerchantRegisterYourDetail.vue";
import MerchantRegisterYourAddress from "../v3_pages/merchant/auth/MerchantRegisterYourAddress.vue";
import MerchantRegisterYourCompany from "../v3_pages/merchant/auth/MerchantRegisterYourCompany.vue";
import MerchantRegisterSetupPassword from "../v3_pages/merchant/auth/MerchantRegisterSetupPassword.vue";
import MerchantConfirmationPage from "../v3_pages/merchant/auth/MerchantConfirmationPage.vue";

// User Login V3
const UserSignIn = async () =>
  await import("../v3_pages/user_v3/auth_login/UserSignIn.vue");
import UserOtp from "../v3_pages/user_v3/auth_login/UserOtp.vue";
import UserRegister from "../v3_pages/user_v3/auth_login/UserRegisterV3.vue";
import UserRegisterYourDetail from "../v3_pages/user_v3/auth_login/UserRegisterYourDetail.vue";
import UserRegisterAddress from "../v3_pages/user_v3/auth_login/UserRegisterAddress.vue";
import UserConfirmationPage from "../v3_pages/user_v3/auth_login/UserConfirmationPage.vue";

const routes = [


  {
    path: "/share",
    name: "share",
    component: Share,
  },
  {
    path: "/admin/login",
    name: "login",
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/password/reset/",
    name: "password-email",
    component: PasswordEmail,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "admin/dashboard",
    name: "layout",
    component: App,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "/admin/logs",
        name: "logApp",
        component: LogApp,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/logs/info",
        name: "logInfo",
        component: LogInfo,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/log-settings",
        name: "logSettings",
        component: LogSettings,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/delete-users-logs",
        name: "deleteUsersLogs",
        component: DeleteUsersLogs,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/notification-logs",
        name: "notificationLogs",
        component: notificationLogs,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/notification-utilities",
        name: "notificationUtilities",
        component: NotificationUtilities,
        meta: { adminRoute: true },
      },

      {
        path: "/dust-logViewer",
        name: "dust-logViewer",
        component: LogViewer,
        meta: { adminRoute: true },
      },
      {
        path: "/session-clear",
        name: "sessionClear",
        component: SessionClear,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/analytics",
        name: "analytics",
        component: Analytics,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/homePageSection",
        name: "homePageSection",
        component: HomePageSection,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/users",
        name: "users",
        component: Users,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/users/:id",
        name: "UserInformationDetail",
        component: UserInformationDetail,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/settings",
        name: "settings",
        component: Settings,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/PluginDownloader",
        name: "PluginDownloader",
        component: PluginDownloader,
        meta: { adminRoute: true },
      },

      {
        path: "/admin/our_partners",
        name: "ourPartners",
        component: OurPartners,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/send_message",
        name: "sendMessage",
        component: SendMessage,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/our_partner/:id",
        name: "galleryRetailer",
        props: true,
        component: GalleryRetailer,

      },
      {
        path: "/admin/website/:id",
        name: "websitegallery",
        props: true,
        component: GalleryRetailer,

      },
      {
        path: "/admin/manage_merchant",
        name: "manageMerchant",
        component: ManageMerchant,
      },
      {
        path: "/admin/manage_merchant/:type",
        name: "manage_Merchant",
        component: ManageMerchant,
      },
      {
        path: "/admin/announcements",
        name: "announcements",
        component: Announcements,
      },
      {
        path: "/admin/walkthrough",
        name: "walkthrough",
        component: Walkthrough,
      },
      {
        path: "/admin/merchant-offers",
        name: "merchantOffers",
        component: MerchantOffers,
      },
      {
        path: "/admin/merchant-coupons",
        name: "merchantCoupons",
        component: MerchantCoupons,
      },
      {
        path: "/admin/packages",
        name: "packages",
        component: AdminPackages,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/contactmessage",
        name: "contactmessage",
        component: ContactMessage,
      },
      {
        path: "/admin/register_logs",
        name: "register_logs",
        component: RegisterLogs,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/reminder_logs",
        name: "reminder_logs",
        component: ReminderLogs,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/manage-merchant/:id",
        name: "merchantProfile",
        component: MerchantViews,
        meta: { adminRoute: true },
      },
      {
        path: "/analytics/:id",
        name: "merchantAnalytics",
        component: Analytics,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/mail-utilities",
        name: "mailUtilities",
        component: MailUtilities,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/mail-logs",
        name: "mailLogs",
        component: MailLogs,
        meta: { adminRoute: true },
      },
      {
        path: "/admin/lookup/categories",
        name: "lookupCategories",
        component: LookupCategory,
      },
      {
        path: "/admin/lookup/categoryQuestion",
        name: "LookupCategoryQuestion",
        component: LookupCategoryQuestion,
      },
      {
        path: "/admin/lookup/categoryQuestion/:id",
        name: "LookupQuestionAndAnswer",
        component: LookupQuestionAndAnswer,
      },

      {
        path: "/admin/userFeedback",
        name: "userFeedback",
        component: UserFeedback,
        meta: { adminRoute: true },
      },
    ],
  },

  //  User Login V3

  {
    path: "/user/signIn",
    name: "userSignIn",
    component: UserSignIn,
  },
  {
    path: "/user/user-otp",
    name: "userOtp",
    component: UserOtp,
  },

  {
    path: "/user/signup",
    name: "userRegister",
    component: UserRegister,
  },
  {
    path: "/user/register-yourDetail",
    name: "userRegisterYourDetail",
    component: UserRegisterYourDetail,
  },
  {
    path: "/user/register-yourAddress",
    name: "userRegisterAddress",
    component: UserRegisterAddress,
  },

  {
    path: "/user/confirmationPage",
    name: "userConfirmationPage",
    component: UserConfirmationPage,
  },

  {
    path: "/user/dashboard",
    name: "userApp",
    component: AppUser,
    meta: { usersRoute: true },
    children: [
      {
        path: "/",
        name: "userDashboard",
        component: UserDashboard,
        meta: { usersRoute: true },
      },
      {
        path: "/user/change-password",
        name: "userChangePassword",
        component: UserChangePassword,
        meta: { usersRoute: true },
      },
      {
        path: "/user/friends",
        name: "userFriends",
        component: userFriends,
        meta: { usersRoute: true },
      },
      {
        path: "/user/txn_history",
        name: "txnHistory",
        component: txnHistory,
        meta: { usersRoute: true },
      },
      {
        path: "/user/my-profile",
        name: "myProfile",
        component: MyProfile,
        meta: { usersRoute: true },
        props: true,
      },

      {
        path: "/user/MobileVerified",
        name: "MobileVerified",
        component: MobileVerified,
        meta: { merchantRoute: true },
      },

      {
        path: "/user/user-address",
        name: "userAddress",
        component: UserAddress,
        meta: { usersRoute: true },
      },
      {
        path: "/user/users-mobile-numbers",
        name: "usersMobileNumbers",
        component: UsersMobileNumbers,
        meta: { usersRoute: true },
      },
      {
        path: "/user/up-coming-birthday",
        name: "upcomingBirthday",
        component: UpcomingBirthday,
        meta: { usersRoute: true },
      },
    ],
  },

  // Merchant
  {
    path: "/v3/merchant/reset-merchant-password",
    name: "merchantResetPassword",
    component: ResetPassword,
  },

  {
    path: "/v3/merchant/signIn",
    name: "merchantSignIn",
    component: MerchantSignIn,
  },
  {
    path: "/merchant/:email/:website",
    name: "merchantSignup",
    component: LoginMerchant,
  },


  {
    path: "/users/:email",
    name: "userLogin",
    component: UserLogin,
  },

  {
    path: "/merchant/onboarding",
    name: "onboarding",
    component: Onboarding,
    children: [
      {
        path: "/merchant/onboarding/welcome",
        name: "welcomeMerchant",
        component: WelcomeMerchant,
      },
      {
        path: "/merchant/onboarding/choose-website",
        name: "chooseWebsite",
        component: ChooseWebsite,
      },
      {
        path: "/merchant/onboarding/install-plugin",
        name: "installPlugin",
        component: InstallPlugin,
      },
      {
        path: "/merchant/onboarding/configure-plugin",
        name: "configurePlugin",
        component: ConfigurePlugin,
      },
    ],
  },


  {
    path: "/merchant/dashboard",
    name: "merchantApp",
    component: AppMerchant,
    meta: { merchantRoute: true },
    children: [
      {
        path: "/merchant/dashboard",
        name: "merchantDashboard",
        component: DashboardMerchant,
        meta: { merchantRoute: true },
      },
      // {
      //   path: '/merchant/packages',
      //   name: 'merchantPackages',
      //   component: Packages,
      //   meta: { merchantRoute: true },
      // },
      {
        path: "/merchant/change-password",
        name: "merchantChangePassword",
        component: MerchantChangePassword,
        meta: { merchantRoute: true },
      },
      // {
      //   path: '/merchant/mail-utilities',
      //   name: 'MailUtilities',
      //   component: MailUtilities,
      //   meta: { merchantRoute: true },
      // },
      // {
      //   path: '/merchant/mail-logs',
      //   name: 'MailLogs',
      //   component: MailLogs,
      //   meta: { merchantRoute: true },
      // },
      // {
      //   path: '/merchant/category',
      //   name: 'Category',
      //   component: Category,
      //   meta: { merchantRoute: true },
      // },

      {
        path: "/merchant/create-token",
        name: "merchantCreateToken",
        component: MerchantCreateToken,
        meta: { merchantRoute: true },
      },
      {
        path: "/merchant/websites",
        name: "websites",
        component: Websites,
        meta: { merchantRoute: true },
      },

      {
        path: "/merchant/website/:id",
        name: "merchantGalleryRetailer",
        component: MerchantGalleryRetailer,
        meta: { merchantRoute: true },
      },

      {
        path: "/merchant/merchant_view/:id",
        name: "companyProfile",
        component: MerchantViews,
        meta: { merchantRoute: true },
      },
      {
        path: "/merchant/shipping_address",
        name: "shippingAddress",
        component: ShippingAddress,
        meta: { merchantRoute: true },
      },
    ],
  },
  //=======USER =======//
  {
    path: "/user_forget_password", //
    name: "user_forget_password",
    component: ForgetPassword,
  },
  {
    path: "/setup_account/:id",
    name: "setup_account",
    component: ForgetPassword,
  },

  {
    path: "/users",
    name: "userLogin",
    component: UserLogin,
    //  meta: { usersRoute: true },
  },
  {
    path: "/user/dashboard",
    name: "userApp",
    component: AppUser,
    meta: { usersRoute: true },
    children: [
      {
        path: "/",
        name: "userDashboard",
        component: UserDashboard,
        meta: { usersRoute: true },
      },
      {
        path: "/user/change-password",
        name: "userChangePassword",
        component: UserChangePassword,
        meta: { usersRoute: true },
      },
      {
        path: "/user/friends",
        name: "userFriends",
        component: userFriends,
        meta: { usersRoute: true },
      },
      {
        path: "/user/txn_history",
        name: "txnHistory",
        component: txnHistory,
        meta: { usersRoute: true },
      },
      {
        path: "/user/my-profile",
        name: "myProfile",
        component: MyProfile,
        meta: { usersRoute: true },
        props: true,
      },

      {
        path: "/user/MobileVerified",
        name: "MobileVerified",
        component: MobileVerified,
        meta: { merchantRoute: true },
      },

      {
        path: "/user/user-address",
        name: "userAddress",
        component: UserAddress,
        meta: { usersRoute: true },
      },
      {
        path: "/user/users-mobile-numbers",
        name: "usersMobileNumbers",
        component: UsersMobileNumbers,
        meta: { usersRoute: true },
      },
      {
        path: "/user/up-coming-birthday",
        name: "upcomingBirthday",
        component: UpcomingBirthday,
        meta: { usersRoute: true },
      },
    ],
  },


  // V3 Routes

  {
    path: '/v3/merchant/signIn',
    name: 'merchantEmailLogin',
    component: MerchantSignIn,
  },

  {
    path: "/v3/merchant/enter-password",
    name: "merchantPasswordLogin",
    component: MerchantEnterPassword,
  },

  {
    path: "/v3/merchant/reset-password",
    name: "merchantV3ResetPassword",
    component: MerchantResetPassword,
  },
  {
    path: "/v3/merchant/signup",
    name: "merchantRegister",
    component: MerchantRegister,
    beforeEnter: (to, from, next) => {
      if (!Vue.prototype.$userAccessToken() || !store.state.auth.loginState) {
        next();
        return;
      }else{
        next({ name: "login" });
      }
    }
  },
  {
    path: "/v3/merchant/otp",
    name: "merchantOtp",
    component: MerchantOtp,
  },
  {
    path: "/v3/merchant/register-yourDetail",
    name: "merchantRegisterYourDetail",
    props: true,
    component: MerchantRegisterYourDetail,
  },
  {
    path: "/v3/merchant/register-yourAddress",
    name: "merchantRegisterYourAddress",
    props: true,
    component: MerchantRegisterYourAddress,
  },
  {
    path: "/v3/merchant/register-yourCompany",
    name: "merchantRegisterYourCompany",
    props: true,
    component: MerchantRegisterYourCompany,
  },
  {
    path: "/v3/merchant/register-setupPassword",
    name: "merchantRegisterSetupPassword",
    props: true,
    component: MerchantRegisterSetupPassword,
  },
  {
    path: "/v3/merchant/confirmationPage",
    name: "merchantConfirmationPage",
    props: true,
    component: MerchantConfirmationPage,
  },

  {
    path: "*",
    name: "pageNotFound",
    component: PageNotFound,
  },
];

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!Vue.prototype.$userAccessToken() || !store.state.auth.loginState) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (Vue.prototype.$userAccessToken() && store.state.auth.loginState) {
    next();
    return;
  }
  next("/");
};

export const router = new VueRouter({
  mode: "history",
  routes,

  scrollBehavior(to, from, savedPosition) {
    // let scrollTo = 0;
    // if (to.hash) {
    //   scrollTo = to.hash;
    // } else if (savedPosition) {
    //   scrollTo = savedPosition.y;
    // } else {
    //   return { x: 0, y: 0 };
    // }
    // return goTo(scrollTo);
    // if (savedPosition) {
    //     return savedPosition;
    // } else {
    //     return { x: 0, y: 0 };
    // }
  },
});

router.beforeEach((to, from, next) => {
  //===================================CHECK MERCHANT ROUTES VALID OR NOT=================================//
  if (to.meta.merchantRoute) {
    if (store.state.merchant_login.loginState &&
      Vue.prototype.$userAccessToken()
    ) {
      next();
    } else {
      next({ name: "  " });
    }
  } else if (to.meta.usersRoute) {
    if (Vue.prototype.$userAccessToken() && store.state.user_login.loginState) {
      next();
    } else {
      next({ name: "userLogin" });
    }
  } else if (to.meta.adminRoute) {
    if (store.state.auth.loginState && Vue.prototype.$userAccessToken()) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }

  //=================================== END CHECK MERCHANT ===============================================//
});

export default router;

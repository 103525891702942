<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">User <span>Registration</span></h4>
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img class="v3-logo" :src="'/assets/img/logo.png'" alt="Logo" />
          <p class="v3-sub-title">One app, All your connections</p>
          <h2>Let's get <br />you started!</h2>

          <p class="v3-sub-p">We've sent an activation code to your email</p>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="7" lg="6">
              <v-otp-input :length="6" v-model="email_verify_otp"></v-otp-input>
              <br />

              <v-row justify="center">
                <p class="v3-underline-text" v-if="!email_resendFlag">
                  Resend code again
                </p>
                <p class="ml-2" v-if="!email_resendFlag">
                  {{ email_timeLeft }}s
                </p>
                <v-btn
                  color="primary"
                  v-if="email_resendFlag"
                  small
                  text
                  @click="resendCode()"
                  >Resend OTP</v-btn
                >
              </v-row>
              <br />

              <v-btn
                class="primary-bg-line-btn"
                :loading="loader"
                @click="verify()"
                depressed
                block
                large
                outlined
              >
                Verify
              </v-btn>
            </v-col>
          </v-row>

          <div
            v-if="this.$route.params.userInfo.createUser"
            class="v3-auth-sub-footer"
          >
            <p class="text-3">
              Already have an account?
              <span @click="signIn">Sign In</span>
            </p>
          </div>

          <div @click="goBack()" class="auth-back-btn">
            <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <v-icon dark size="20px"> mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
  
  <script>
import { mapState } from "vuex";
import MerchantBannerWidget from "../../merchant/auth/MerchantBannerWidget.vue";
import axios from "axios";
import Cookies from "js-cookie";
import UAParser from "ua-parser-js";
export default {
  components: {
    MerchantBannerWidget,
  },
  computed: {
    ...mapState({}),
  },

  data: () => ({
    //Email Verification keys
    email_otp_send: false,
    email_confirmStatus: false,
    email_verify_otp: "",
    email_verify: false,
    email_code_confirm_dialog: false,
    email_resendFlag: false,
    email_timeLeft: 30,
    signup_from_login_dialog: false,
    auto_fill_click: false,
    showAddField: false,
    unique_mobile: true,
    is_mail_loader: false,
    is_phone_loader: false,
    status_email: 1,
    terms: "",
    terms_conditions: false,
    dialog: false,
    show1: false,
    show2: false,
    loader: false,

    user: {
      mobile_number: "",
    },
    snackbar: {
      show: false,
      message: "",
      color: null,
    },
    termsDialog: false,
  }),

  created() {
    var params = this.$route.params;
    if (Object.keys(params).length == 0) {
      this.$router.replace("/user/signin");
    }
    this.req_data = params;
    this.email_timeLeft = 30;
    this.time();
  },

  methods: {
    resendCode() {
      if (this.$route.params.userInfo.createUser) {
        this.crateUserResendCode();
      } else {
        this.loginResendCode();
      }
    },

    loginResendCode() {
      const form = new FormData();
      form.append("phone", this.req_data.userInfo.mobile_number);
      form.append("country_code", this.req_data.userInfo.country_code);
      form.append("type", "web");

      axios
        .post("/api/v3/web_login", form)
        .then((response) => {
          if (response.data.status === 200) {
            this.email_otp_send = true;
            this.email_resendFlag = false;
            this.email_timeLeft = 30; // Set the initial timer value
            this.time(); // Start the timer
            this.snackbar.show = true;
            this.snackbar.color = "primary";
            this.snackbar.message = "OTP sent to your Email";
            this.existing_account = response.data.existing_account;
          } else {
            this.email_otp_send = false;
            this.errorSnackBar(response.data.message);
            n;
          }
        })
        .catch((e) => {
          this.email_otp_send = false;
          this.errorSnackBar(e.response.data.message);
        });
    },

    crateUserResendCode() {
      const form = new FormData();
      form.append("phone", this.req_data.userInfo.mobile_number);
      form.append("country_code", this.req_data.userInfo.country_code);
      form.append("type", "web");
      axios
        .post("/api/v3/webUserSignUp", form)
        .then((response) => {
          if (response.data.status == 200) {
            this.email_otp_send = true;
            this.email_resendFlag = false;
            this.email_timeLeft = 30;
            this.time();
            this.snackbar.show = true;
            this.snackbar.color = "primary";
            this.snackbar.message = "OTP send your Email";
            this.existing_account = response.data.existing_account;
          } else {
            this.email_otp_send = false;
            this.errorSnackBar(response.data.message);
          }
        })
        .catch((e) => {
          this.email_otp_send = false;
          this.errorSnackBar(e.response.data.message);
        });

      return;
    },

    time() {
      if (this.email_timeLeft > 0) {
        setTimeout(() => {
          this.email_timeLeft--;
          this.time(); // Recursive call to continue the countdown
        }, 1000); // Update every 1 second (1000 milliseconds)
      } else {
        // Time's up, allow resend
        this.email_resendFlag = true;
      }
    },

    goBack() {
      if (this.$route.params.userInfo.createUser) {
        this.$router.replace("/user/signup");
      } else {
        this.$router.replace("/user/signin");
      }
    },
    signIn() {
      this.$router.push({ name: "userSignIn" });
    },

    verify() {
      if (this.$route.params.userInfo.createUser) {
        this.otpVerificationCreateUser();
      } else {
        this.otpVerification();
      }
    },

    async otpVerificationCreateUser() {
      if (!this.email_verify_otp) {
        var msg = "Please enter Otp";
        this.errorSnackBar(msg);

        this.snackbar = {
          message: msg,
          color: "red",
          show: true,
        };
        return false;
      }

      // Verify otp with server side api
      const form = new FormData();
      form.append("phone", this.$route.params.userInfo.mobile_number);
      form.append("country_code", this.$route.params.userInfo.country_code);
      form.append("otp", this.email_verify_otp);
      form.append("type", "web");

      await this.$recaptchaLoaded();
      const GCtoken = await this.$recaptcha("userOtp");
      form.append("GCtoken", GCtoken);
      this.loader = true;

      axios
        .post("/api/v3/registerUserVerifyOtp", form)
        .then((response) => {
          this.loader = false;
          if (response.data.status == 200) {
            this.$router.push({
              name: "userRegisterYourDetail",
              params: { userInfo: response.data.data },
            });

            this.email_verify = true;
            this.email_resendFlag = false;
            this.email_confirmStatus = true;
            this.email_otp_send = false;
          } else {
            this.email_email_verify = false;
            this.errorSnackBar(response.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loader = false;
          this.email_verify = false;
          this.snackbar = {
            message: e.response.data.message,
            color: "red",
            show: true,
          };
        });
      return;
    },

    async otpVerification() {
      if (!this.email_verify_otp) {
        var msg = "Please enter Otp";
        this.errorSnackBar(msg);
        this.snackbar = {
          message: msg,
          color: "red",
          show: true,
        };
        return false;
      }

      // Verify otp with server side api
      const form = new FormData();
      form.append("phone", this.$route.params.userInfo.mobile_number);
      form.append("country_code", this.$route.params.userInfo.country_code);
      form.append("otp", this.email_verify_otp);
      form.append("type", "web");
      var parser = new UAParser();
      var uastring1 = navigator.userAgent;
      parser.setUA(uastring1);
      var result = parser.getResult();
      var result = UAParser(uastring1);
      let arch = result.cpu
        ? result.cpu.architecture
        : result.device
        ? result.device.type
        : "";

      let device_detail = {
        os: result.os.name,
        platform: "browser",
        os_version: result.os.version,
        device_name: result.browser.name,
        device_arch: arch,
        platform_version: result.browser.version,
      };
      form.append("device_detail", JSON.stringify(device_detail));

      await this.$recaptchaLoaded();
      const GCtoken = await this.$recaptcha("userOtp");
      form.append("GCtoken", GCtoken);
      this.loader = true;

      axios
        .post("/api/v3/verify_otp", form)
        .then((response) => {
          this.loader = false;
          if (response.data.status == 200) {
            let token = "Bearer " + response.data.data.access_token;
            axios.defaults.headers.common["Authorization"] = token;
            var remember = true;
            var now = new Date();
            now.setTime(now.getTime() + 1 * 3600 * 1000);
            Cookies.set("access_token", token, { expires: remember ? 365 : 1 });

            this.$store.commit("USER_LOGIN_STATUS", true);
            this.$store.commit("SHOW_SNACKBAR", {
              success: true,
              message: "Login successfully.",
            });
            this.$router.push({ name: "userDashboard" });

            this.email_verify = true;
            this.email_resendFlag = false;
            this.email_confirmStatus = true;
            this.email_otp_send = false;
          } else {
            this.email_email_verify = false;
            this.errorSnackBar(response.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loader = false;
          this.email_verify = false;
          this.snackbar = {
            message: e.response.data.message,
            color: "red",
            show: true,
          };
        });
      return;
    },

    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },
  },
};
</script>
  
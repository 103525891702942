import axios from "axios";
import { reject } from "lodash";

const state = {
    merchant_websites_logs :[] ,
    merchant_verified_count:[],
    loaderstate:false ,
    total_api_hits : 3000 ,
    loading:true ,
    chart1:{
        data: {
            chartData: {
                labels: ["Range 1", "Range 2"],
                datasets: [{
                    borderWidth: 1,
                    borderColor: ["rgba(255,99,132,1)", "#B5B5B5"],
                    backgroundColor: ["#EED6D7", "#a0000a"],
                    data: [4, 4]
                }]
            }
        }
    } ,
    chart2:{
        data: {
            chartData: {
                labels: ["Range 1", "Range 2"],
                datasets: [{
                    borderWidth: 1,
                    borderColor: ["rgba(255,99,132,1)", "#B5B5B5"],
                    backgroundColor: ["#EED6D7", "#a0000a"],
                    data: [4, 4]
                }]
            }
        }
    } ,

    users_count : [0,0],
    merchant_count : [0,0],

};

const getters = {};

const actions = {


    get_websites_logs(context, data) {
    
        var form = new FormData() ;
        // form.append('user_id' , merchant_id) ;
        form.append('date_from' , data.date_from) ;
        form.append('date_to' , data.date_to) ;

        var api_url = "/api/merchant/get_websites_logs";
        
       
        axios
        .post(api_url, form)
            .then(response => {
                 context.commit("SET_WEBSITE_LOGS", response.data.data);
            })
            .catch(error => {

            });

    },

    get_api_hits(context, data) {

        var form = new FormData() ;
        // form.append('user_id' , merchant_id) ;
        form.append('date_from' , data.date_from) ;
        form.append('date_to' , data.date_to) ;

        var api_url = "/api/admin/get_api_hits";
        
       
        axios
        .post(api_url, form)
            .then(response => {
                 context.commit("SET_API_HITS", response.data.data);
            })
            .catch(error => {

            });

    },
 
    
    get_charts_data(context, data) {
 
        return new Promise((resolve , reject) => {

        var dates = data.dates;
          
        var api_url = "/api/admin/get_charts_data";
        var form = new FormData() ;
        form.append("range1_start" , data.dates.date1[0]) ;
        form.append("range1_end" , data.dates.date1[1]) ;
        form.append("range2_start" , data.dates.date2[0]) ;
        form.append("range2_end" , data.dates.date2[1]) ;
        form.append('user_type' , data.user_type) ;
       

        axios
            .post(api_url , form)
            .then(response => {

                resolve(response)   
                if (response.data.status =='200') {

                    var date_range1 = dates.date1[0] +' ~ '+ dates.date1[1]
                    var date_range2 = dates.date2[0] +' ~ '+ dates.date2[1]

                    var date_range3 = dates.date1[0] +' ~ '+ dates.date3[1]
                    var date_range4 = dates.date2[0] +' ~ '+ dates.date4[1]

                    if(data.user_type == Vue.prototype.$constants.USER){

                        context.commit("SET_USERS_COUNT", response.data.data);
                       
                        // context.commit("SET_CHART_ONE", {
                        //     data:response.data.data,
                        //     date_range1: date_range1,
                        //     date_range2: date_range2,
                        // });

                        // state.chart1.data.chartData.datasets[0].data = response.data.data;
                        // state.chart1.data.chartData.labels = [date_range1 , date_range2];

                    }

                    if(data.user_type == Vue.prototype.$constants.MERCHANT){
                        
                        context.commit("SET_MERCHNAT_COUNT", response.data.data);

                        // context.commit("SET_CHART_TWO", {
                        //     data:response.data.data,
                        //     date_range1: date_range3,
                        //     date_range2: date_range4,
                        // });

                        // state.chart2.data.chartData.datasets[0].data = response.data.data;
                        // state.chart2.data.chartData.labels = [date_range3 , date_range4];

                    }
                
                }
               
            })
            .catch(error => {
               
                reject(error)
                //(error);
                
            });
        });
    },

     
}




const mutations = {
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
      },
    SET_WEBSITE_LOGS(state, data) {
        state.merchant_websites_logs = data;
    },

    SET_API_HITS(state, data) {
        state.total_api_hits = data;
    },
    GET_MERCHANT_COUNT(state, data){
        state.merchant_verified_count = data;
    },

    SET_USERS_COUNT(state, data) {
        state.users_count = data;
    },

    SET_MERCHNAT_COUNT(state, data) {
        state.merchant_count = data;
    },

    
    SET_CHART_ONE(state, data) {
        
        state.chart1.data.chartData.datasets[0].data = data.data;
        state.chart1.data.chartData.labels = [data.date_range1 , data.date_range2];
        //Vue.prototype.$printLog("=================state.chart1.  ===>", JSON.stringify(state.chart1));

    },
    SET_CHART_TWO(state, data) {
        state.chart2.data.chartData.datasets[0].data = data.data;
        state.chart2.data.chartData.labels = [data.date_range1 , data.date_range2];
        //Vue.prototype.$printLog("=================state.chart1.  ===>", JSON.stringify(state.chart2));

    },


};

export default {
    state,
    getters,
    actions,
    mutations,
};
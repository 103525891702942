<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">User <span>Registration</span></h4>
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />

          <p class="v3-sub-title">One app, All your connections</p>
          <h2 class="mt-5">Getting to know you</h2>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7">
              <div>
                <p>Your Details</p>
                <div class="d-flex mb-3"></div>
                <v-row justify="center">
                  <v-col class="py-0" cols="12">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="First Name"
                      label="First Name"
                      outlined
                      v-model="user.firstname"
                      :error-messages="firstNameErrors"
                      @input="$v.user.firstname.$touch()"
                      @blur="$v.user.firstname.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col class="py-0" cols="12" sm="6" md="6">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Middle Name"
                      label="Middle Name"
                      outlined
                      v-model="user.middle_name"
                    ></v-text-field>
                  </v-col>
                  <!-- :error-messages="jobTitleErrors"
                      @input="$v.user.middle_name.$touch()"
                      @blur="$v.user.middle_name.$touch()"
                      required -->
                  <br />

                  <v-col class="py-0" cols="12" sm="6" md="6">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Last Name"
                      label="Last Name"
                      outlined
                      v-model="user.lastname"
                      :error-messages="lastNameErrors"
                      @input="$v.user.lastname.$touch()"
                      @blur="$v.user.lastname.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col class="py-0" cols="12" sm="6" md="6">
                    <v-select
                      :items="genderList"
                      label="Gender"
                      outlined
                      background-color="white"
                      class="h50-field"
                      v-model="user.gender"
                      :error-messages="genderErrors"
                      @input="$v.user.gender.$touch()"
                      @blur="$v.user.gender.$touch()"
                      required
                    ></v-select>
                  </v-col>
                  <br />

                  <v-col class="py-0" cols="12" sm="6" md="6">
                    <v-dialog
                      ref="dialog1"
                      v-model="date_of_birthday_dialog"
                      :return-value.sync="user.date_of_birth"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          background-color="white"
                          class="mr-2 ml-2 h50-field"
                          placeholder="Date of Birthday"
                          label="Date of Birthday"
                          outlined
                          v-model="user.date_of_birth"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          center
                          prepend-inner-icon="mdi-calendar"
                          dense
                          :error-messages="dateOfBirthDayErrors"
                          @input="$v.user.date_of_birth.$touch()"
                          @blur="$v.user.date_of_birth.$touch()"
                          required
                        ></v-text-field>
                        <!-- :disabled="searchLoading" -->
                      </template>
                      <v-date-picker
                        color="primary"
                        v-model="user.date_of_birth"
                        scrollable
                        :max="current_date"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="start_date_model = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog1.save(user.date_of_birth)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col class="py-0" cols="12">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Email"
                      label="Email"
                      v-model="user.email"
                      :error-messages="emailError"
                      @input="$v.user.email.$touch()"
                      @blur="$v.user.email.$touch()"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col class="py-0" cols="12">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Referral Code"
                      label="Referral Code"
                      v-model="user.referral_code"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col class="py-2" cols="12">
                    <v-btn
                      :loading="loading"
                      class="primary-bg-line-btn"
                      large
                      outlined
                      block
                      @click="yourDetails('user_info')"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br />
              <br />
              <br />
              <br />
              <div class="merchant-step-area">
                <p>1/2</p>
                <img
                  :src="'/assets/v3_assets/custom_shapes/m_step_2.png'"
                  alt="Logo"
                />
              </div>
              <div @click="goBack()" class="auth-back-btn">
                <v-btn class="v3-div-clr" elevation="0" depressed flex>
                  <v-icon dark size="20px"> mdi-arrow-left</v-icon>
                  Back
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
  
  <script>
import { mapState } from "vuex";
import MerchantBannerWidget from "../../merchant/auth/MerchantBannerWidget.vue";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  props: ["userInfo"],
  components: { MerchantBannerWidget },
  computed: {
    ...mapState({}),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.user.firstname.$dirty) return errors;
      !this.$v.user.firstname.required &&
        errors.push("First Name is required.");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.user.lastname.$dirty) return errors;
      !this.$v.user.lastname.required && errors.push("Last Name is required.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.user.gender.$dirty) return errors;
      !this.$v.user.gender.required && errors.push("Gender is required.");
      return errors;
    },
    dateOfBirthDayErrors() {
      const errors = [];
      if (!this.$v.user.date_of_birth.$dirty) return errors;
      !this.$v.user.date_of_birth.required &&
        errors.push("Date of Birthday is required.");
      return errors;
    },

    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.email && errors.push("Must be valid e-mail");
      !this.$v.user.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
  created() {
    if (this.$route.params.userInfo) {
      var item = this.$route.params.userInfo;
      this.user.user_id = item.user_id;
      this.user.firstname = item.last_name;
      this.user.lastname = item.last_name;
    } else {
      this.$router.replace("/user/signup");
    }
  },
  data: () => ({
    date_of_birthday_dialog: false,
    current_date: new Date().toISOString().slice(0, 10),
    loading: false,

    firebase_error: false,
    snackbar: {
      show: false,
      message: "",
      color: null,
    },

    genderList: [
      { text: "Male", value: "0" },
      { text: "Female", value: "1" },
      { text: "Non-binary", value: "2" },
      { text: "Transgender", value: "3" },
      { text: "Intersex", value: "4" },
      { text: "Prefer not to answer", value: "5" },
    ],
    user: {
      user_id: "",
      firstname: "",
      lastname: "",
      middle_name: "",
      email: "",
      gender: "",
      referral_code: "",
      date_of_birth: new Date(
        new Date().setFullYear(new Date().getFullYear() - 12)
      )
        .toISOString()
        .slice(0, 10),
    },
  }),
  validations: {
    user: {
      firstname: { required },
      lastname: { required },
      email: "",
      gender: {
        required,
      },
      date_of_birth: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    goBack() {
      this.$router.replace("/user/signup");
    },
    async yourDetails(value) {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.snackbar = {
          message: "Fill in all the fields",
          color: "red",
          show: true,
        };
      } else {
        const form = new FormData();
        form.append("user_id", this.user.user_id);
        form.append("firstname", this.user.firstname);
        form.append("lastname", this.user.lastname);
        if (this.user.middle_name) {
          form.append("middle_name", this.user.middle_name);
        }
        form.append("gender", this.user.gender);
        form.append("date_of_birth", this.user.date_of_birth);
        form.append("deviceType", 2);
        form.append("email", this.user.email);
        form.append("section", value);
        form.append("referral_code", this.user.referral_code ?? "");
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("userRegisterYourDetail");
        form.append("GCtoken", GCtoken);
        this.loading = true;
        axios
          .post("/api/v3/user_register", form)
          .then((response) => {
            this.loading = false;
            this.$router.push({
              name: "userRegisterAddress",
              params: { userInfo: response.data.data },
            });
          })
          .catch((e) => {
            this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
            this.loading = false;
          });
      }
    },
    //***************************************Phone Otp Related Method********************************************************************************** */
    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },

    // ************************************Email***************************************************************************
  },
};
</script>
  
  <style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
  